<template>
	<div
		:class="['collapse-menu', { 'collapse-menu--collapsed': modelValue }]"
		:style="{ width: modelValue ? collapsedWidth : defaultWidth }">
		<div
			class="collapse-menu__wrapper"
			:style="{ backgroundColor: color }">
			<div
				:class="['collapse-menu__header', { 'collapse-menu__header--offer': offer }]"
				:style="{ padding: modelValue ? collapsedPadding : defaultPadding }">
				<div class="collapse-menu__header-content">
					<slot name="header" />

					<button
						class="collapse-menu__button-collapse"
						:title="modelValue ? 'Развернуть меню' : 'Свернуть меню'"
						@click="toggleCollapse">
						<NuxtIcon
							class="nuxt-icon--no-fill"
							name="double-arrow" />
					</button>
				</div>
			</div>

			<div class="collapse-menu__body">
				<div class="collapse-menu__menu">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	const props = defineProps({
		modelValue: {
			type: Boolean,
			required: true,
		},
		defaultWidth: {
			type: String,
			default: "330px",
		},
		collapsedWidth: {
			type: String,
			default: "76px",
		},
		defaultPadding: {
			type: String,
			required: false,
		},
		collapsedPadding: {
			type: String,
			required: false,
		},
		color: {
			type: String,
			default: "var(--light-3)",
		},
		offer: {
			type: Boolean,
			required: false,
		},
	});

	const emits = defineEmits(["update:modelValue", "onChange"]);

	function toggleCollapse() {
		emits("update:modelValue", !props.modelValue);
		emits("onChange", props.modelValue);
	}
</script>

<style lang="scss">
	@import "collapse-menu";
</style>
